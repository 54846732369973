import React from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom'


function Profile({currentUser}) {
    const location = useLocation()
    return (
    <Box sx={{ paddingTop: '30px', textAlign: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            borderRadius: '50%',
            width: { xs: '120px', sm: '150px', md: '200px' },
            height: { xs: '120px', sm: '150px', md: '200px' },
            overflow: 'hidden',
            background: currentUser.imageUrl
              ? 'none'
              : 'linear-gradient(45deg, #46b5e5 1%, #1e88e5 64%, #40baf5 97%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          {currentUser.imageUrl ? (
            <img
              src={currentUser.imageUrl}
              alt={currentUser.name}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '50%',
              }}
            />
          ) : (
            <Typography
              sx={{
                fontSize: { xs: '2em', sm: '2.5em', md: '3em' },
                color: '#fff',
                lineHeight: 1,
              }}
            >
              {currentUser.name && currentUser.name[0]}
            </Typography>
          )}
        </Box>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 500,
            fontSize: { xs: '14px', sm: '16px', md: '18px' },
            marginBottom: '10px',
          }}
        >
          {currentUser.name}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 400,
            fontSize: { xs: '12px', sm: '14px', md: '16px' },
          }}
        >
          {currentUser.email}
        </Typography>
      </Box>
    </Box>
       
    );
}

export default Profile;
