
import React from 'react';
import { Box, Button, Typography, TextField } from '@mui/material';
import { GOOGLE_AUTH_URL, FACEBOOK_AUTH_URL, GITHUB_AUTH_URL, ACCESS_TOKEN } from '../../constants';
import { login } from '../../util/APIUtils';
import { Link, Navigate, useNavigate, useLocation } from 'react-router-dom';
import googleLogo from '../../img/google-logo.png';

const Login = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    if (props.authenticated) {
        return <Navigate to={{ pathname: '/', state: { from: location } }} />;
    }

    return (
        <Box sx={{ textAlign: 'center', mt: 3 }}>
            <Box
                sx={{
                    backgroundColor: '#fff',
                    boxShadow: '0 1px 11px rgba(0, 0, 0, 0.27)',
                    borderRadius: 2,
                    width: { xs: '90%', sm: '500px' },
                    display: 'inline-block',
                    mt: 1,
                    position: 'relative',
                    padding: { xs: 3, sm: 4 },
                }}
            >
                <Typography
                    variant="h5"
                    sx={{ fontWeight: 500, mt: 0, mb: 3, color: 'rgba(0, 0, 0, 0.65)', fontSize: { xs: '1em', sm: '1.5em' } }}
                >
                    Login to PrepFury
                </Typography>
                <SocialLogin />
                <Box sx={{ mt: 3, mb: 3, textAlign: 'center' }}>
                    <Typography sx={{ fontSize: '14px', color: '#aaa' }}>OR</Typography>
                </Box>
                <LoginForm navigate={navigate} />
                <Typography sx={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: { xs: '12px', sm: '14px' }, mt: 2 }}>
                    New user? <Link to="/signup">Sign up!</Link>
                </Typography>
            </Box>
        </Box>
    );
};

const SocialLogin = () => {
    return (
        <Box>
              <Button
                href={GOOGLE_AUTH_URL}
                variant="outlined"
                fullWidth
                sx={{
                    mb: 2,
                    fontWeight: 400,
                    fontSize: { xs: '12px', sm: '16px' },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textTransform: 'none',
                    color: 'rgba(0, 0, 0, 0.65)',
                    position: 'relative',
                }}
            >
                <Box sx={{ position: 'absolute', left: '10px' }}>
                    <img src={googleLogo} alt="Google" style={{ height: '24px' ,marginTop:'7px'}} />
                </Box>
                Log in with google
            </Button>
            {/* Uncomment for Facebook and Github login buttons */}
            {/*
            <Button
                href={FACEBOOK_AUTH_URL}
                variant="outlined"
                fullWidth
                sx={{
                    mb: 2,
                    fontWeight: 400,
                    fontSize: { xs: '12px', sm: '16px' },
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <img src={fbLogo} alt="Facebook" style={{ height: '20px', marginRight: '10px' }} /> Log in with Facebook
            </Button>
            <Button
                href={GITHUB_AUTH_URL}
                variant="outlined"
                fullWidth
                sx={{
                    mb: 2,
                    fontWeight: 400,
                    fontSize: { xs: '12px', sm: '16px' },
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <img src={githubLogo} alt="Github" style={{ height: '20px', marginRight: '10px' }} /> Log in with Github
            </Button>
            */}
        </Box>
    );
};

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName]: inputValue,
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        const loginRequest = Object.assign({}, this.state);

        login(loginRequest)
            .then((response) => {
                localStorage.setItem(ACCESS_TOKEN, response.accessToken);
                this.props.navigate('/');
            })
            .catch((error) => {
                // Handle error
            });
    }

    render() {
        return (
            <Box component="form" onSubmit={this.handleSubmit}>
                <TextField
                    type="email"
                    name="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    required
                />
                <TextField
                    type="password"
                    name="password"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={this.state.password}
                    onChange={this.handleInputChange}
                    required
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                >
                    Login
                </Button>
            </Box>
        );
    }
}

export default Login;
