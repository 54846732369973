import React, { useState} from 'react'
import './Quiz.css'
import { styled } from '@mui/material/styles';
import { Card, CardMedia, CardContent, Rating, Grid, CardActions, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Divider, Typography, Button, Stack } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';


const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});

export default function Topic({ topic, authenticated }) {

  const navigate = useNavigate()
  const [examMode, setExamMode] = useState("exam")

  const gotoQuestions = () => {
    const deadline = Date.now()+topic.timeAllowed*60*1000
    navigate('/practice-questions', { state: { quiz_id: topic.topicId, attempted_test_id: uuidv4(), deadline: deadline, correct_marks: topic.correctMarks, 
      incorrect_marks: topic.incorrectMarks, comprehension_count: 0, total_questions: topic.totalQuestions, 
      exam_mode: 'practice', exam_name: topic.examName, quiz_title: topic.title} });
  }

  return (
    <Grid item key={topic.topic_id} xs={12} md={4} style={{ display: 'flex'}}>
      <Card sx={{ minWidth: 300, bgcolor: '#F44336', color: '#FFFFFF' }} elevation={2} style={{ display: 'flex',justifyContent:'space-between', flexDirection:'column'}}>
          {topic.url ?
          <CardMedia
            component="img"
            height="140"
            image={topic.url}
            alt={topic.title}
          />:""}
          <CardContent>
            <Typography gutterBottom variant="h7" component="div" align='center'>
              {topic.shortName}
            </Typography> 
            <Typography gutterBottom variant="h5" component="div">
              {topic.title}
            </Typography> 
            <Divider color="white"/>
            <Stack direction="row" sx={{justifyContent:"space-between"}}>
              <Typography variant="caption" align="left">Questions:</Typography>
              <Typography variant="body1" align="right">{topic.totalQuestions}</Typography>
            </Stack>
            <Stack direction="row" sx={{justifyContent:"space-between"}}>
              <Typography variant="caption" align="left">Allowed Time:</Typography>
              <Typography variant="body1" align="right">{topic.timeAllowed}</Typography>
            </Stack>  
            {/* <StyledRating
              name="customized-color"
              size="small"
              defaultValue={quiz.rating}
              getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
              precision={0.5}
              icon={<FavoriteIcon fontSize="inherit" />}
              emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
            /> */}
          </CardContent>
          <CardActions style={{justifyContent: 'center'}}>
            <Button size="small" variant="contained" onClick={gotoQuestions}>Begin Practice</Button>            
          </CardActions>         
      </Card>
    </Grid>
  )
}
