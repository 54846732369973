
import React, { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { GOOGLE_AUTH_URL } from '../../constants';
import { signup } from '../../util/APIUtils';
import {
    Box,
    Button,
    TextField,
    Typography,
    Modal,
} from '@mui/material';
import googleLogo from '../../img/google-logo.png';

const Signup = (props) => {
    if (props.authenticated) {
        return <Navigate to={{ pathname: '/', state: { from: props.location } }} />;
    }

    return (
        <Box component="main" sx={{ textAlign: 'center', mt: 3 }}>
            <Box
                sx={{
                    backgroundColor: '#fff',
                    boxShadow: '0 1px 11px rgba(0, 0, 0, 0.27)',
                    borderRadius: 2,
                    width: { xs: '90%', sm: '500px' },
                    display: 'inline-block',
                    mt: 1,
                    position: 'relative',
                    padding: { xs: 3, sm: 4 },
                }}
            >
                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: 500,
                        mt: 0,
                        mb: 3,
                        color: 'rgba(0, 0, 0, 0.65)',
                        fontSize: { xs: '1em', sm: '1.5em' },
                    }}
                >
                    Signup to PrepFury
                </Typography>
                <SocialSignup />
                <Box sx={{ mt: 3, mb: 3, textAlign: 'center' }}>
                    <Typography sx={{ fontSize: '14px', color: '#aaa' }}>OR</Typography>
                </Box>
                <SignupForm {...props} />
                <Typography
                    variant="body2"
                    sx={{
                        mt: 2,
                        color: 'rgba(0, 0, 0, 0.65)',
                        fontSize: '14px',
                    }}
                >
                    Already have an account? <Link to="/login">Login!</Link>
                </Typography>
            </Box>
        </Box>
    );
};

const SocialSignup = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mb: 2 }}>
        <Button
            href={GOOGLE_AUTH_URL}
            variant="outlined"
            fullWidth
            sx={{
                mb: 2,
                fontWeight: 400,
                fontSize: { xs: '12px', sm: '16px' },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textTransform: 'none',
                color: 'rgba(0, 0, 0, 0.65)',
                position: 'relative',
            }}
        >
            <Box sx={{ position: 'absolute', left: '10px' }}>
                <img src={googleLogo} alt="Google" style={{ height: '24px', marginTop: '7px' }} />
            </Box>
            Sign up with Google
        </Button>
    </Box>
);

const SignupForm = (props) => {
    const [state, setState] = useState({ name: '', email: '', password: '', showSuccessPopup: false });
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const signUpRequest = { name: state.name, email: state.email, password: state.password };

        signup(signUpRequest)
            .then(() => {
                setState((prevState) => ({ ...prevState, showSuccessPopup: true }));
                setTimeout(() => {
                    navigate('/login');
                }, 3000);
            })
            .catch((error) => {
                console.error('Signup failed:', error);
            });
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoComplete="name"
                autoFocus
                value={state.name}
                onChange={handleInputChange}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={state.email}
                onChange={handleInputChange}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={state.password}
                onChange={handleInputChange}
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Sign Up
            </Button>
            <Modal open={state.showSuccessPopup} onClose={() => setState({ ...state, showSuccessPopup: false })}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxWidth: '500px',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="h6" component="h2">
                        Success!
                    </Typography>
                    <Typography sx={{ mt: 2 }}>You're successfully registered.</Typography>
                    <Typography sx={{ mt: 2 }}>Redirecting to login...</Typography>
                </Box>
            </Modal>
        </Box>
    );
};

export default Signup;
