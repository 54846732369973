import React, { useState} from 'react'
import './Quiz.css'
import { styled } from '@mui/material/styles';
import { Card, CardMedia, CardContent, Rating, Grid, CardActions, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Divider, Typography, Button, Stack } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';


const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});

export default function Quiz({ quiz, authenticated }) {

  const navigate = useNavigate()
  const [examMode, setExamMode] = useState("exam")

  const gotoQuiz = () => {
    const deadline = Date.now()+quiz.timeAllowed*60*1000
    navigate('/questions', { state: { quiz_id: quiz.id, attempted_test_id: uuidv4(), deadline: deadline, correct_marks: quiz.correctMarks, 
      incorrect_marks: quiz.incorrectMarks, comprehension_count: quiz.comprehensionCount, total_questions: quiz.totalQuestions, 
      exam_mode: 'exam', exam_name: quiz.examName, quiz_title: quiz.title} });
  }

  const handleChange = (ev) => {
    setExamMode(ev.target.value)
  }
  return (
    <Grid item key={quiz.id} xs={12} md={4} style={{ display: 'flex'}}>
      <Card sx={{ minWidth: 300, bgcolor: '#F44336', color: '#FFFFFF' }} elevation={2} style={{ display: 'flex',justifyContent:'space-between', flexDirection:'column'}}>
          {quiz.url ?
          <CardMedia
            component="img"
            height="140"
            image={quiz.url}
            alt={quiz.title}
          />:""}
          <CardContent>
            <Typography gutterBottom variant="h7" component="div" align='center'>
              {quiz.shortName}
            </Typography> 
            <Typography gutterBottom variant="h5" component="div">
              {quiz.title}
            </Typography> 
            <Divider color="white"/>
            <Stack direction="row" sx={{justifyContent:"space-between"}}>
              <Typography variant="caption" align="left">Questions:</Typography>
              <Typography variant="body1" align="right">{quiz.totalQuestions}</Typography>
            </Stack>
            <Stack direction="row" sx={{justifyContent:"space-between"}}>
              <Typography variant="caption" align="left">Allowed Time:</Typography>
              <Typography variant="body1" align="right">{quiz.timeAllowed}</Typography>
            </Stack>  
            <Stack direction="row" sx={{justifyContent:"space-between"}}>
            <Typography variant="caption" align="left">Mode:</Typography>
            <RadioGroup  defaultValue="exam"
              onChange={handleChange} value={examMode}
              name="mod-rbg" align="right">
              <FormControlLabel key="exam" value="exam" labelPlacement="start" control={<Radio size='small' />} label={<Typography variant="caption" color="white">Exam</Typography>} />
              <FormControlLabel key="practice" value="practice" labelPlacement="start" control={<Radio size='small'/>} label={<Typography variant="caption" color="white">Practice</Typography>} />
            </RadioGroup>    
            </Stack>
            {/* <StyledRating
              name="customized-color"
              size="small"
              defaultValue={quiz.rating}
              getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
              precision={0.5}
              icon={<FavoriteIcon fontSize="inherit" />}
              emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
            /> */}
          </CardContent>
          <CardActions style={{justifyContent: 'center'}}>
            {authenticated?
            <Button size="small" variant="contained" onClick={gotoQuiz}>Begin Test</Button>:
            <Button size="small" variant="contained" component={Link} to="/login">Login to Take Test</Button>}
            
          </CardActions>         
      </Card>
    </Grid>
  )
}
