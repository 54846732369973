import * as React from 'react';
import { Box, Container, Paper, Typography,Grid } from '@mui/material';
import { Link } from 'react-router-dom';


function AppFooter() {
  return (
    <Paper sx={{marginTop: 'calc(10% + 60px)',
      width: '100%',
      position: 'fixed',
      bottom: 0,
      width: '100%'
      }} component="footer" square variant="outlined">
        <Container maxWidth="lg">
        <Grid container
          sx={{ flexGrow: 1, justifyContent: 'space-between',display: 'flex',flexDirection: { xs: 'column', sm: 'row' },textAlign: { xs: 'center', sm: 'left' }}}>
            <Grid item xs={12} sm={6}>
            <Typography variant="caption" color="initial">
              Copyright ©2025. CLOUDMEDLEY LLP.
            </Typography>
            </Grid>
            <Grid
            item
            xs={12}
            sm={6}
             >
            <Typography variant="caption">
              <Link to="/privacy-policy">Privacy Policy</Link>&nbsp;|&nbsp;
              <Link to="/refunds">Refund Policy</Link>&nbsp;|&nbsp;
              <Link to="/terms">Terms & Conditions</Link>       
            </Typography>
            </Grid>

          </Grid>
        </Container>
      </Paper>
  );
}
export default AppFooter;